<template>

  <b-container fluid id="app" class="body-content cover-full"> 
    
      <!-- NAVBAR-->
      <b-row align-v="center" class="back-home-body-b">
        <b-col sm="12">
          <b-navbar  toggleable="md"  sticky fixed="top" style="background-color: rgba(255, 255, 255, 0.7); " >
            <b-navbar-brand to="" class="mx-3">
              <img src="@/assets/logo horitzontal.png" style="height: 35px;" class="d-inline-block align-top" >
            </b-navbar-brand>   
          
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <b-navbar-nav  class="mx-auto ">
                <b-nav-item @click="$router.push('/home')" class="mx-4 " ><b class=" menu-text">Home</b></b-nav-item >
                <b-nav-item-dropdown right>
                  <!-- Using 'button-content' slot -->
                  <template #button-content>
                    <b class=" menu-text">Real Estate</b>
                  </template>
                  <b-dropdown-item @click="$router.push('/real')"><b class=" menu-text">Tulum</b></b-dropdown-item>
                  <b-dropdown-item ><b class=" menu-text">Playa del Carmen</b></b-dropdown-item>
                </b-nav-item-dropdown>
                <b-nav-item @click="$router.push('/yoga')" class="mx-4"><b class=" menu-text">Yoga</b></b-nav-item>                
                <b-nav-item @click="$router.push('/blog')" class="mx-4"><b class=" menu-text">Blog</b></b-nav-item>
                <b-nav-item @click="$router.push('/contact')" class="mx-4"><b class=" menu-text">Contact</b></b-nav-item>

              </b-navbar-nav>               
            </b-collapse>
              
          </b-navbar>
        </b-col>
      </b-row>

    <b-row align="center" align-h="center" style="min-height:780px;">
      <b-col cols="12" >
        <router-view/>
      </b-col>
    </b-row>
    
    <b-modal v-model="showAvisoDePrivacidad" size="lg" title="Aviso De Privacidad" hide-footer>
      <h1>En constucción ...</h1>
    </b-modal>

    <b-modal v-model="showTerminosYCondiciones" size="lg" title="Terminos Y Condiciones" hide-footer>
      <h1>En constucción ...</h1>
    </b-modal>


  <vue-whatsapp-widget phoneNumber="+529841674506" textReply="Typically replies within 5 minutes" companyName="Susi C. McDonald" />
  </b-container>
  
</template>

<style >


#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#footer {
  background: linear-gradient(90deg, rgba(255,31,31,1) 0%, rgba(220,0,0,1) 100%);
  color: #fff;
}

.img {
  border-radius: 20px;
  height: 300px;
  width: 300px;
  box-shadow: 5px 5px 20px -2px #5f5f5f;
}

.img-carousel{
  width: 100%;
}

.pointer {
  cursor: pointer;
}

.body-content {
  background-color: white;
}

.target-card {
  background-color: red;
  border-radius: 2em;
  border: 1px solid;
  padding: 10px;
  box-shadow: 5px 5px rgb(170, 163, 163);
  color: #fff;
}

.cover-full {
  padding-left: 0 !important;
  padding-right: 0 !important;
  width: 100% !important;
}

@font-face {
  font-family: 'Crossten Extrabold';
  src: url('assets/fonts/Horizon Type - Crossten Extrabold.otf') 

}

@font-face {
  font-family: 'Bernardo Moda Bold';
  src: url('assets/fonts/Bernardo Moda Bold.ttf') 

}


.menu-text{
  font-family: 'Bernardo Moda Bold';
  font-size: 150%;
  color:black;
}

.real-text{
  font-family: 'Bernardo Moda Bold';
  font-size: 150%;
  color:purple;
}

.form-label-class{
  color:purple;
  font-weight: bold;
}

.form-input{
  background-image: url('assets/arenaform.jpg');
}

.form-input-c{
  color:purple;
  font-weight: bold;
  /*background-image: url('assets/arenaform.jpg');*/

}
    
      
  
.p-text {
  font-family: 'Crossten Extrabold';
}

.p-text-l {
  font-family: 'Bernardo Moda Bold';
}

.titles {
  font-family: 'Crossten Extrabold';
}


.social-links{ 
  color: white !important; 
  text-decoration: none;
}

.social-icons {
  width: 20px; 
  height: 20px;
}

.icons-cards {
  width: 120px; height: 120px;
}


/* styles flipbook*/



@media (min-width: 871px)  {
  .offset-flipbook {
    margin-bottom:15px;
  }
}
@media (min-width: 781px) and (max-width: 870px) {
  .offset-flipbook {
    margin-bottom:-20px;
  }
}
@media (min-width: 641px) and (max-width: 780px) {
  .offset-flipbook {
    margin-bottom:-40px;
  }
}
@media (min-width: 589px) and (max-width: 640px) {
  .offset-flipbook {
    margin-bottom:-75px;
  }
}
@media (min-width: 521px) and (max-width: 588px) {
  .offset-flipbook {
    margin-bottom:15px;
  }
}
@media (min-width: 451px) and (max-width: 520px) {
  .offset-flipbook {
    margin-bottom:30px;
  }
}
@media (max-width: 450px) {
  .offset-flipbook {
    margin-bottom:-20px;
  }
}



@media (min-width: 871px)  {
  .offset-button-flipbook {
    margin-top:75px;
  }
}
@media (min-width: 781px) and (max-width: 870px) {
  .offset-button-flipbook {
    margin-top:5px;
  }
}
@media (min-width: 641px) and (max-width: 780px) {
  .offset-button-flipbook {
     margin-top:-50px;
  }
}
@media (min-width: 589px) and (max-width: 640px) {
  .offset-button-flipbook {
    margin-top: -115px;
  }
}
@media (min-width: 521px) and (max-width: 588px) {
  .offset-button-flipbook {
    margin-top: 70px;
  }
}

@media (min-width: 451px) and (max-width: 520px) {
  .offset-button-flipbook {
    margin-top:75px;
  }
}

@media (max-width: 450px) {
  .offset-button-flipbook {
    margin-top: -10px;
  }
}




.action-bar {
  width: 100%;
  height: 30px;
  padding: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;

}
.action-bar .btn {
  font-size: 30px;
  color: black;
}
.action-bar .btn svg {
  bottom: 0;
}
.action-bar .btn:not(:first-child) {
  margin-left: 10px;
}
.has-mouse .action-bar .btn:hover {
  color: black;
  filter: drop-shadow(1px 1px 5px #000);
  cursor: pointer;
}
.action-bar .btn:active {
  filter: none !important;
}
.action-bar .btn.disabled {
  color: black;
  pointer-events: none;
}
.action-bar .page-num {
  font-size: 12px;
  margin-left: 10px;
}


.flipbook .viewport {
  width: 200vw;
  /*height: calc(100vh - 50px - 40px);*/
  height: 100vh;
}

.flipbook {
  height: 90vh;
}

.flipbook .bounding-box {
  box-shadow: 5px 5px 20px -2px #5f5f5f;
}


#flipbook-body {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: #ccc;
}

.c-shadow-sm{
  box-shadow: 5px 5px 20px -2px #5f5f5f;
  margin-bottom:10px;
  border-radius:25px !important;
}
.img-supposition-rounded{
  background: linear-gradient(90deg, rgba(28,81,141,1) 0%, rgba(0,57,120,1) 100%);
  box-shadow: 4px 4px 15px 1px #5f5f5f;
  border-radius: 50%;
  font-size:20px;
  padding:20px;
  width: 98px;
  height: 98px;
  position:absolute;
  top:-49px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;
}

.back-home-body-b{
  background-image: url('assets/texturasusyarenaclara.jpg'); 
  background-size: 100% auto;
}


</style>


 <script>
var TwitterWidgetsLoader = require("twitter-widgets");
import VueWhatsappWidget from 'vue-whatsapp-widget'

export default {
  components: {
      VueWhatsappWidget
  },
  mounted() {
    TwitterWidgetsLoader.load();
  },
  data() {
    return {
      showAvisoDePrivacidad:false,
      showTerminosYCondiciones:false
    }
  }
};
</script>

