import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/index.html',
    redirect: '/home'
  },
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/yoga',
    name: 'Yoga',
    component: () => import('../views/Yoga.vue')
  },
  {
    path: '/real',
    name: 'Real',
    component: () => import( '../views/Real.vue')
  },
  {
    path: '/p101',
    name: 'p101',
    component: () => import('../views/p101.vue')
  },
  {
    path: '/p102',
    name: 'p102',
    component: () => import( '../views/p102.vue')
  },
  {
    path: '/p103',
    name: 'p103',
    component: () => import( '../views/p103.vue')
  },
  {
    path: '/p104',
    name: 'p104',
    component: () => import( '../views/p104.vue')
  },
  {
    path: '/blog',
    name: 'Blog',
    component: () => import('../views/Blog.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import( '../views/Contact.vue')
  },
  {
    path: '/whitepage',
    name: 'WhitePage',
    component: () => import( '../views/WhitePage.vue')
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
